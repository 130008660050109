import React, { Component } from 'react';
import { HZBusinessImgList } from '../../../assets'
import './index.less'
import Banner from './Banner';
import { Banner50DataSource, } from './data.source';
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
        };

    }
    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
    }

    render() {
        const { isMobile } = this.state
        return (
            <div className='main'>
                <Banner
                    id="Banner5_0"
                    key="Banner5_0"
                    dataSource={Banner50DataSource}
                />,

                {
                    isMobile ? <div className='mainBox'>
                        <div className='solutionBox'>
                            <div className='solutionBoxTitleBox'>
                                <h1>和正业务（ERP）解决方案</h1>
                                <p>企业ERP作为三军的大本营，保证根基的稳固、通过完善的商业管理平台，降低企业管理、运营的成本</p>
                            </div>
                            <div className='solutionBoxImgBox'>
                                <img src={HZBusinessImgList[0].url} alt="" />
                            </div>
                        </div>
                        <h1 style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '36px' }}>核心方案</h1>

                        <div className='mainSolutionBox'>
                            <div className='mainSolutionBoxImg'>
                                <img src={HZBusinessImgList[1].url} alt="" />
                            </div>
                            <div className='mainSolutionBoxContent'>
                                <p>业务流程管控</p>
                                <span>靠信息流带动企业的资金流和商品流的同步；用信息流反应企业的资金流、反应企业的货币流；让资金流和物流能够同步。数据准确，可追溯</span>
                            </div>

                        </div>


                        <div className='competitivenessBox'>
                            <div className='competitivenessBoxImg'>
                                <img src={HZBusinessImgList[2].url} alt="" />
                            </div>
                            <div className='competitivenessBoxContent'>
                                <p>核心着力点</p>
                                <span>员工基础信息、员工调动、权限管理；门店员工缴款对账，全渠道支付平台互通、商品状态、单店单品&门店差异化、重点商品管理、保质期、标准化管理、规范化管理、精细化管理</span>
                            </div>

                        </div>

                        <div className='supportBox'>
                        <div className='supportBoxImg'>
                                <img src={HZBusinessImgList[3].url} alt="" />
                            </div>
                            <div className='supportBoxContent'>
                                <p>核心系统支持</p>
                                <span>支持多层级组织架构：总部、区域总部、区域子公司、门店等。对门店进行分类管理，包括促销、商品配置管理、商品业务控制等，提升营运管理水平。支持门店向多个总部要货等。支持实时销售、实时流水、实时全渠道打通、实时库存等，提高全渠道业务能力。支持不停业盘点、高库存低库存实施监管、移动加权计价等特色功能
                                    &门店差异化、重点商品管理、保质期、标准化管理、规范化管理、精细化管理</span>
                            </div>
                           
                        </div>


                    </div> : <div className='mainBox'>
                        <div className='solutionBox'>
                            <div className='solutionBoxTitleBox'>
                                <h1>和正业务（ERP）解决方案</h1>
                                <p>企业ERP作为三军的大本营，保证根基的稳固、通过完善的商业管理平台，降低企业管理、运营的成本</p>
                            </div>
                            <div className='solutionBoxImgBox'>
                                <img src={HZBusinessImgList[0].url} alt="" />
                            </div>
                        </div>
                        <h1 style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '36px' }}>核心方案</h1>

                        <div className='mainSolutionBox'>
                            <div className='mainSolutionBoxContent'>
                                <p>业务流程管控</p>
                                <span>靠信息流带动企业的资金流和商品流的同步；用信息流反应企业的资金流、反应企业的货币流；让资金流和物流能够同步。数据准确，可追溯</span>
                            </div>
                            <div className='mainSolutionBoxImg'>
                                <img src={HZBusinessImgList[1].url} alt="" />
                            </div>
                        </div>


                        <div className='competitivenessBox'>
                            <div className='competitivenessBoxImg'>
                                <img src={HZBusinessImgList[2].url} alt="" />
                            </div>
                            <div className='competitivenessBoxContent'>
                                <p>核心着力点</p>
                                <span>员工基础信息、员工调动、权限管理；门店员工缴款对账，全渠道支付平台互通、商品状态、单店单品&门店差异化、重点商品管理、保质期、标准化管理、规范化管理、精细化管理</span>
                            </div>

                        </div>

                        <div className='supportBox'>
                            <div className='supportBoxContent'>
                                <p>核心系统支持</p>
                                <span>支持多层级组织架构：总部、区域总部、区域子公司、门店等。对门店进行分类管理，包括促销、商品配置管理、商品业务控制等，提升营运管理水平。支持门店向多个总部要货等。支持实时销售、实时流水、实时全渠道打通、实时库存等，提高全渠道业务能力。支持不停业盘点、高库存低库存实施监管、移动加权计价等特色功能
                                    &门店差异化、重点商品管理、保质期、标准化管理、规范化管理、精细化管理</span>
                            </div>
                            <div className='supportBoxImg'>
                                <img src={HZBusinessImgList[3].url} alt="" />
                            </div>
                        </div>


                    </div>
                }

            </div>
        );
    }
}


export default index;