import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <span {...dataSource.img}>
            <img src={dataSource.img.children} width="100%" alt="img" />
          </span>
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <h2 key="h1" {...dataSource.title}>
            {dataSource.title.children}
          </h2>
          <div key="p1" {...dataSource.CouponTitle1}>
            {dataSource.CouponTitle1.children}
          </div>
          <div key="p2" {...dataSource.Couponcontent1}>
            {dataSource.Couponcontent1.children}
          </div>
          <div key="p3" {...dataSource.CouponTitle2}>
            {dataSource.CouponTitle2.children}
          </div>
          <div key="p4" {...dataSource.Couponcontent2}>
            {dataSource.Couponcontent2.children}
          </div>
          <div key="p5" {...dataSource.CouponTitle3}>
            {dataSource.CouponTitle3.children}
          </div>
          <div key="p6" {...dataSource.Couponcontent3}>
            {dataSource.Couponcontent3.children}
          </div>
          <div key="p7" {...dataSource.CouponTitle4}>
            {dataSource.CouponTitle4.children}
          </div>
          <div key="p8" {...dataSource.Couponcontent4}>
            {dataSource.Couponcontent4.children}
          </div>
          <div key="p9" {...dataSource.CouponTitle5}>
            {dataSource.CouponTitle5.children}
          </div>
          <div key="p10" {...dataSource.Couponcontent5}>
            {dataSource.Couponcontent5.children}
          </div>
       
         
        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content1;
