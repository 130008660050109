import React, { Component, Suspense,  } from 'react';

import {
  HashRouter as Router,
  useRoutes
} from 'react-router-dom';
import { enquireScreen } from 'enquire-js';
import Navheader from './components/NavHeader.jsx';
import Footer from './components/Footer.jsx'
import Products from "./pages/Products"//产品
import Solution from "./pages/Solution"//解决方案
import Home from './pages/Home';//首页
import AboutUs from './pages/AboutHZ';
import {
  Nav00DataSource,
  Footer10DataSource,
} from './pages/Home/data.source.js';


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const GetRoutes = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <Home></Home>,
    },
    {
      path: `/products/fusion`,
      element: <Products
      ></Products>,
    },
    {
      path: `/products/ronghuitong`,
      element: <Products
      ></Products>,
    },
    {
      path: `/products/wukongPOS`,
      element: <Products
      ></Products>,
    },
    {
      path: `/products/ronghuiyuan`,
      element: <Products
      ></Products>,
    },
    {
      path: `/AboutUs`,
      element: <AboutUs
      ></AboutUs>,
    },
    {
      path: `/Solution/business`,
      element: <Solution
      ></Solution>,
    },
    
  ]);
  return routes
}
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,

    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    return (
      <Router>
        <Navheader
          id="Nav0_0"
          key="Nav0_0"
          dataSource={Nav00DataSource}
          isMobile={this.state.isMobile}
        />
        <Suspense>
          <GetRoutes />
        </Suspense>
        <Footer
          id="Footer1_0"
          key="Footer1_0"
          dataSource={Footer10DataSource}
          isMobile={this.state.isMobile}
        />
      </Router>

    );
  }
}

export default App;