import React, { Component } from 'react';
import { Carousel } from 'antd';
import {BannerImgList} from '../../assets'


class banner2 extends Component {
    

    onChange = (a, b, c) => {
        console.log(a, b, c);
    }
    render() {
        return (
            <Carousel 
            afterChange={e=>this.onChange()}
             autoplay="true"
             dots="true"
             >
            <div style={{
                width:"100%",
            }}>
             <img
             style={{
                width:"100%",
                height:"100%"
            }}
             src={BannerImgList[0].url} alt="" />
            </div>
            <div style={{
                width:"100%",
            }}>
             <img
             style={{
                width:"100%",
                height:"100%"
            }}
             src={BannerImgList[1].url} alt="" />
            </div>
            <div style={{
                width:"100%",
            }}>
             <img
             style={{
                width:"100%",
                height:"100%"
            }}
             src={BannerImgList[2].url} alt="" />
            </div>
           
          </Carousel>
        );
    }
}

banner2.propTypes = {

};

export default banner2;