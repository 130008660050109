export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: '产品名', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: '产品标语介绍',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: '产品的详细说明，如是什么东西之类的文字',
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#',
          className: 'banner5-button',
          type: 'primary',
          children: '开始使用',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
  },
};

export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper private-wrapper' },
  OverPack: { className: 'home-page private', playScale: 0.3 },
  imgWrapper: { className: 'private-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/private.png"),
  },
  textWrapper: { className: 'private-text', md: 14, xs: 24 },
  title: { className: 'private-title', children: '私有云架构优势兼备' },
  privateTitle1: {
    className: 'private-contentTitle',
    children:'技术架构稳定性高',
  },
  privatecontent1: {
    className: 'private-content',
    children:
      '具备高性能、高可靠度、长时间运行等特点，打通数据传输环节的任督二脉',
  },
  privateTitle2: {
    className: 'private-contentTitle',
    children:'服务器投入成本低',
  },
  privatecontent2: {
    className: 'private-content',
    children:
      '实现“数据库上云”，只需一台云服务器，即可保证所有业务正常流转运行',
  },
  privateTitle3: {
    className: 'private-contentTitle',
    children:'软件运行效率快',
  },
  privatecontent3: {
    className: 'private-content',
    children:
      '基于HTTP方式的三层架构，消除传统模式性能瓶颈，让软件即开即用',
  },
  privateTitle4: {
    className: 'private-contentTitle',
    children:'业务数据安全性强',
  },
  privatecontent4: {
    className: 'private-content',
    children:
      '数据仅用户独享，无私密性安全隐患，保障企业运营信息的独立和私有化',
  },
  privateTitle5: {
    className: 'private-contentTitle',
    children:'多商户管理灵活',
  },
  privatecontent5: {
    className: 'private-content',
    children:
      '一台云服务器同时承载多个商户，服务器托管便于集中式资源管理和服务运维',
  },
};

export const MemberPortraitDataSource = {
  wrapper: { className: 'home-page-wrapper MemberPortrait-wrapper' },
  OverPack: { className: 'home-page MemberPortrait', playScale: 0.3 },
  imgWrapper: { className: 'MemberPortrait-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/portrayal.png"),
  },
  textWrapper: { className: 'MemberPortrait-text', md: 14, xs: 24 },
  title: { className: 'MemberPortrait-title', children: '全渠道会员画像' },
  MemberPortraitTitle1: {
    className: 'MemberPortrait-contentTitle',
    children:'',
  },
  MemberPortraitcontent1: {
    className: 'MemberPortrait-content',
    children:
      '',
  },
  MemberPortraitTitle2: {
    className: 'MemberPortrait-contentTitle',
    children:'',
  },
  MemberPortraitcontent2: {
    className: 'MemberPortrait-content',
    children:
      '深度挖掘客户价值，提供可靠的营销数据支撑',
  },
  MemberPortraitTitle3: {
    className: 'MemberPortrait-contentTitle',
    children:'',
  },
  MemberPortraitcontent3: {
    className: 'MemberPortrait-content',
    children:
      '个性化定制会员标签，全方位覆盖会员属性',
  },
  MemberPortraitTitle4: {
    className: 'MemberPortrait-contentTitle',
    children:'',
  },
  MemberPortraitcontent4: {
    className: 'MemberPortrait-content',
    children:
      '洞悉会员轨迹，多维度刻画会员画像',
  },
  MemberPortraitTitle5: {
    className: 'MemberPortrait-contentTitle',
    children:'',
  },
  MemberPortraitcontent5: {
    className: 'MemberPortrait-content',
    children:
      '关怀活跃会员，唤醒沉睡会员',
  },
};


export const CouponDataSource = {
  wrapper: { className: 'home-page-wrapper Coupon-wrapper' },
  OverPack: { className: 'home-page Coupon', playScale: 0.3 },
  imgWrapper: { className: 'Coupon-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/vip.png"),
  },
  textWrapper: { className: 'Coupon-text', md: 14, xs: 24 },
  title: { className: 'Coupon-title', children: '多种卡券覆盖全消费场景' },
  CouponTitle1: {
    className: 'Coupon-contentTitle',
    children:'代金券',
  },
  Couponcontent1: {
    className: 'Coupon-content',
    children:
      '支持按全场或单品、品牌、品类生成代金券',
  },
  CouponTitle2: {
    className: 'Coupon-contentTitle',
    children:'折扣券',
  },
  Couponcontent2: {
    className: 'Coupon-content',
    children:
      '支持按全场或单品、品牌、品类生成折扣券，可设置最高折扣额度',
  },
  CouponTitle3: {
    className: 'Coupon-contentTitle',
    children:'礼品券',
  },
  Couponcontent3: {
    className: 'Coupon-content',
    children:
      '可在门店前台进行券核销，礼品兑换',
  },
  CouponTitle4: {
    className: 'Coupon-contentTitle',
    children:'支付券',
  },
  Couponcontent4: {
    className: 'Coupon-content',
    children:
      '微信支付券、支付宝支付券，支付时自动抵扣',
  },
  CouponTitle5: {
    className: 'Coupon-contentTitle',
    children:'异业券',
  },
  Couponcontent5: {
    className: 'Coupon-content',
    children:
      '异业联盟不同业态间流量互换',
  },
  
};


export const IntegralDataSource = {
  wrapper: { className: 'home-page-wrapper Integral-wrapper' },
  OverPack: { className: 'home-page Integral', playScale: 0.3 },
  imgWrapper: { className: 'Integral-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/ka.png"),
  },
  textWrapper: { className: 'Integral-text', md: 14, xs: 24 },
  title: { className: 'Integral-title', children: '实体卡、电子卡全支持' },
  IntegralTitle1: {
    className: 'Integral-contentTitle',
    children:'',
  },
  Integralcontent1: {
    className: 'Integral-content',
    children:
      '多介质、多渠道，线上线下积分统一、储值统一',
  },
  
  
};
export const MarketingSystemDataSource = {
  wrapper: { className: 'home-page-wrapper MarketingSystem-wrapper' },
  OverPack: { className: 'home-page MarketingSystem', playScale: 0.3 },
  imgWrapper: { className: 'MarketingSystem-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/yx.png"),
  },
  textWrapper: { className: 'MarketingSystem-text', md: 14, xs: 24 },
  title: { className: 'MarketingSystem-title', children: '丰富的营销体系' },
  MarketingSystemTitle1: {
    className: 'MarketingSystem-contentTitle',
    children:'',
  },
  MarketingSystemcontent1: {
    className: 'MarketingSystem-content',
    children:
      '注册营销、拉新营销、支付营销、消费营销、互动营销',
  },
 
 
 
};

export const InformationDataSource = {
  wrapper: { className: 'home-page-wrapper information-wrapper' },
  OverPack: { className: 'home-page information', playScale: 0.3 },
  imgWrapper: { className: 'information-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/information.png"),
  },
  textWrapper: { className: 'information-text', md: 14, xs: 24 },
  title: { className: 'information-title', children: '数据安全省心放心' },
  informationTitle1: {
    className: 'information-contentTitle',
    children:'本地备份',
  },
  informationcontent1: {
    className: 'information-content',
    children:
      '属于表级/行级备份，自动备份关键业务数据，服务器中毒后一键恢复数据，快速恢复营业',
  },

  informationTitle2: {
    className: 'information-contentTitle',
    children:'云备份',
  },
  informationcontent2: {
    className: 'information-content',
    children:
      '属于数据库级备份，定时备份完整数据库并存储到云空间，畅享云资源带来的福利',
  },

  informationTitle3: {
    className: 'information-contentTitle',
    children:'数据检测',
  },
  informationcontent3: {
    className: 'information-content',
    children:
      '提供数据检测功能，检测档案数据可能存在的风险和问题，可一键修复',
  },
  
};