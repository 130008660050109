import React, { PureComponent } from 'react';
import { enquireScreen } from 'enquire-js';
import * as echarts from 'echarts';
import china from './js/china'
import { geoCoordMap, QDData, CDData, SZData, } from "./js/mymap"
import data from './js/china';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
class index extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isMobile,
        };
    }
    componentDidMount() {
        this.loadMap()
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        //   if (location.port) {
        //     // 样式 build 时间在 200-300ms 之间;
        //     // setTimeout(() => {
        //     //   this.setState({
        //     //     show: true,
        //     //   });
        //     // }, 500);
        //   }
        /* 如果不是 dva 2.0 请删除 end */
    }

    loadMap = () => {
        const { isMobile } = this.state

        var myChart = echarts.init(document.querySelector('.map'));
        echarts.registerMap('china', china)
        const planePath = 'path://M.6,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705';

        var convertData = function (data) {
            var res = [];
            for (var i = 0; i < data.length; i++) {
                var dataItem = data[i];
                var fromCoord = geoCoordMap[dataItem[0].name];
                var toCoord = geoCoordMap[dataItem[1].name];
                if (fromCoord && toCoord) {
                    res.push([{
                        coord: fromCoord
                    }, {
                        coord: toCoord
                    }]);
                }
            }
            return res;
        };




        var color = ['#3ed4ff', '#ffa022', '#a6c84c'];
        var series = [];
        [
            ['青岛', QDData],
            ['成都', CDData],
            ['深圳', SZData],
        ].forEach(function (item, i) {
            series.push(
                {
                    name: item[0],
                    geoIndex: 0,
                    type: 'lines',
                    zlevel: 1,
                    effect: {
                        show: true,
                        period: 6,
                        trailLength: 0.7,
                        color: '#fff',
                        symbolSize: 3
                    },
                    lineStyle: {
                        normal: {
                            color: color[i],
                            width: 0,
                            curveness: 0.2
                        }
                    },
                    data: convertData(item[1])

                }, {
                name: item[0],
                type: 'lines',
                zlevel: 2,
                effect: {
                    show: true,
                    period: 6,
                    trailLength: 0,
                    symbol: planePath,
                    symbolSize: 15
                },
                lineStyle: {
                    normal: {
                        color: color[i],
                        width: 1,
                        opacity: 0.4,
                        curveness: 0.2
                    }
                },
                data: convertData(item[1])
            }, {
                name: item[0],
                type: 'effectScatter',
                coordinateSystem: 'geo',
                zlevel: 2,
                rippleEffect: {
                    brushType: 'stroke'
                },
                label: {
                    normal: {
                        show: true,
                        position: 'right',
                        formatter: '{b}'
                    }
                },
                symbolSize: function (val) {
                    return val[2] / 8;
                },
                itemStyle: {
                    normal: {
                        color: color[i]
                    }
                },
                data: item[1].map(function (dataItem) {
                    return {
                        name: dataItem[1].name,
                        value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value])
                    };
                })

            });
            console.log(series)
        });

        let option = {
            backgroundColor: '#101129',
            title: {
                x: 'center',
                y: '40',
                text: '实施与服务',
                itemGap: isMobile ? 20 : 40,
                textStyle: {
                    color: 'white',
                    fontSize: 32,
                    fontWeight: "normal",

                },
                subtext: isMobile ? '设有服务专家直接在电话中处理问题；\n基于两个研发中心青岛、成都，两个营销中心深圳、青岛，\n合作伙伴杭州、沈阳、成都、郑州、深圳、南京具备服务、实施能力。' : '设有服务专家直接在电话中处理问题；基于两个研发中心青岛、成都，两个营销中心深圳、青岛，合作伙伴杭州、沈阳、成都、郑州、深圳、南京具备服务、实施能力。',
                subtextStyle: {
                    color: 'white',
                    fontSize: isMobile ? 12 : 14,
                    padding: 20,
                },
            },
            tooltip: {
                show: true,
                trigger: 'item',
                triggerOn: 'mousemove|click',
                formatter: (parms) => {
                    console.log(parms)
                    var str = ''
                        if (parms.data.name == "青岛" || parms.data.name == '成都' || parms.data.name == '深圳') {
                            if (parms.data.name == '青岛') {
                                str = ` <div style="font-weight: bold;font-size:14px">${parms.data.name}</div>
                            <div>${parms.marker}研发营销中心</div>`
                            }
                            if (parms.data.name == '成都') {
                                str = ` <div>${parms.data.name}</div>
                            <div>${parms.marker}研发中心</div>`
                            }
                            if (parms.data.name == '深圳') {
                                str = ` <div>${parms.data.name}</div>
                            <div>${parms.marker}营销中心</div>`
                            }
                        } else {
                            str = ` <div>${parms.data.name}</div>
                            <div>${parms.marker}办事处</div>`
                        }
                    return parms.data.name ? str : ''
                }
            },

            legend: {
                orient: 'vertical',
                top: 'bottom',
                left: 'right',
                data: ['北京 Top10', '上海 Top10', '广州 Top10'],
                textStyle: {
                    color: '#fff'
                },
                selectedMode: 'single'
            },
            geo: {
                map: 'china',
                zoom: 1.4,
                // selectedMode:'multiple',
                label: {
                    emphasis: {
                        show: false
                    }
                },
                roam: false,
                itemStyle: {
                    normal: {
                        areaColor: '#142957',
                        borderColor: '#0692a4'
                    },
                    emphasis: {
                        areaColor: '#0b1c2d'
                    }
                },
                layoutCenter: isMobile ? ['50%', '80%'] : ['50%', '75%'],
                // 如果宽高比大于 1 则宽度为 100，如果小于 1 则高度为 100，保证了不超过 100x100 的区域
                layoutSize: isMobile ? 250 : 640,

            },
            series: series
        };
        myChart.setOption(option);
    }



    render() {
        return (
            <div className='mapBox' style={{
            }}>

                <div className="map" style={{
                    width: '100%',
                    height: isMobile ? "400px" : "800px",
                    textAlign: 'center',
                    margin: '0 auto ',
                }}>
                </div>
            </div>
        );
    }
}

index.propTypes = {

};

export default index;