


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ScrmImgList } from '../../../assets'
class test extends Component {
    render() {
        return (
            <div className='ScrmMain'>
                <div className='ScrmMainBox'>

                    <div className='ScrmMainBoxTitle'>
                        <p>赋能传统实体门店&nbsp;助力门店完成智慧零售转型</p>
                    </div>
                    <div className='ScrmMainBoxList'>
                        {
                            ScrmImgList.map((item, index) => {
                                return <div className='ScrmMainBoxItem'>

                                    <img src={item.url} key={index}></img>

                                    <p>{item.name}</p>

                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

test.propTypes = {

};

export default test;