export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: '悟空POS', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: '全渠道触点型终端一体化产品',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: '',
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#',
          className: 'banner5-button',
          type: 'primary',
          children: '开始使用',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
  },
};

export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper wukongcontent1-wrapper' },
  OverPack: { className: 'home-page wukongcontent1', playScale: 0.3 },
  imgWrapper: { className: 'wukongcontent1-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/storeLink.png"),
  },
  textWrapper: { className: 'wukongcontent1-text', md: 14, xs: 24 },
  title: { className: 'wukongcontent1-title', children: '店店通' },
  content: {
    className: 'wukongcontent1-content',
    title:{children:'移动化办公',className:'wukongcontent1-contenttitleSpan'},
    children:
      '店店通，是和正为实体零售企业客户打造的一款高效的门店经营管理工具，这款移动端产品通过“看板”、“报表”和“店务”三大模块，致力于应用大数据以协助门店促进销售、加强内控，真正开启门店经营移动化智能变革。',
  },
};

export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper POScontent2-wrapper' },
  OverPack: { className: 'home-page POScontent2', playScale: 0.3 },
  imgWrapper: { className: 'POScontent2-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/wuKongpos.png"),
  },
  textWrapper: { className: 'POScontent2-text', md: 14, xs: 24 },
  title: { className: 'POScontent2-title', children: '悟空POS' },
  content: {
    className: 'POScontent2-content',
    title:{children:'高效、简洁、易上手',className:'POScontent2-contenttitleSpan'},
    children:
      '和正悟空POS支持windows、android系统，其坚持 “高效，稳定的终端”理念、借助于统一逻辑的边缘服务，提供专业的收银管理、店务管理、精准会员营销等智慧解决方案，助力门店运营。/秒级并发能力，严格保证交易数据统一性。金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
  },
};


export const Content20DataSource = {
  wrapper: { className: 'home-page-wrapper buffercontent1-wrapper' },
  OverPack: { className: 'home-page buffercontent1', playScale: 0.3 },
  imgWrapper: { className: 'buffercontent1-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/Buffer.png"),
  },
  textWrapper: { className: 'buffercontent1-text', md: 14, xs: 24 },
  title: { className: 'buffercontent1-title', children: '悟空自助' },
  content: {
    className: 'buffercontent1-content',
    title:{children:'降本提效，提升购物体验',className:'buffercontent1-contenttitleSpan'},
    children:
      '和正根据自身对零售行业的经验积累，推出了顾客自助购物的相关产品。帮助超市、社区店、无人店、专卖店等，实现支付不用等、刷脸即支付的智慧收银场景，提升了收银效率，解决消费高峰期因排队导致客户流失的问题。',
  },
};



export const wukongPOSminiDataSource = {
  wrapper: { className: 'home-page-wrapper wukongPOSmini-wrapper' },
  OverPack: { className: 'home-page wukongPOSmini', playScale: 0.3 },
  imgWrapper: { className: 'wukongPOSmini-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/wukongPOSmini.png"),
  },
  textWrapper: { className: 'wukongPOSmini-text', md: 14, xs: 24 },
  title: { className: 'wukongPOSmini-title', children: '悟空POSmini版' },
  content: {
    className: 'wukongPOSmini-content',
    title:{children:'轻便、小巧、移动收银',className:'wukongPOSmini-contenttitleSpan'},
    children:
      '悟空POSmini版是一款专业实用、操作简单的移动POS收银软件。是中小实体店铺收银记账、会员管理的必备管理工具。它具有低成本，一键安装，使用简单，不受地域限制，无需专业维护，自动升级等特点，真正做到了把店铺管理流程装在手机里，帮助商家实现店铺实务精细化管理，让管理更高效！',
  },
};