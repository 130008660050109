import React, { Component } from 'react';
import Business from "./HZBusiness"
class index extends Component {

    constructor(props) {
        super(props)
     
    }

    componentDidMount() {
        
        this.SelectProduct()
    }




    SelectProduct = () => {
        if (window.location.href.includes('business')) {
            return <Business></Business>
        }
    }
    render() {
 
        return (
            <div>
                { this.SelectProduct()}
            </div>
        );
    }
}

index.propTypes = {

};

export default index;
