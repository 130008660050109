export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: '悟空POS', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: '全渠道触点型终端一体化产品',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: '',
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#',
          className: 'banner5-button',
          type: 'primary',
          children: '开始使用',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:require("../../../assets/erpSolutionBanner.png")
  },
};

