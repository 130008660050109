/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Banner from './Banner2';
import Private from './Private';
import Oto from './Oto';
import Management from './Management';
import Information from './Information';
import Checkstand from './Checkstand';
import Operation from './Operation';


import {
  Content10DataSource,
  Feature20DataSource,
  ManagementDataSource,
  InformationDataSource,
  CheckstandDataSource,
  OperationDataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class RongHui extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    if (location.port) {
  
      this.setState({
        show: true,
      });
    }
  }

  render() {
    const children = [
      // <Banner5
      //   id="Banner5_0"
      //   key="Banner5_0"
      //   dataSource={Banner50DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Banner
        id="Banner"
        key="Banner"

      />,

      <Private
        id="Content1_0"
        key="Content1_0"
        dataSource={Content10DataSource}
        isMobile={this.state.isMobile}
      />,

      <Oto
        id="Oto"
        key="Oto"
        dataSource={Feature20DataSource}
        isMobile={this.state.isMobile}
      />,
      <Management
        id="Management"
        key="Management"
        dataSource={ManagementDataSource}
        isMobile={this.state.isMobile}
      />,
      <Checkstand
        id="Checkstand"
        key="Checkstand"
        dataSource={CheckstandDataSource}
        isMobile={this.state.isMobile}
      />,
      <Operation
      id="Operation"
      key="Operation"
      dataSource={OperationDataSource}
      isMobile={this.state.isMobile}
    />,
      <Information
        id="Information"
        key="Information"
        dataSource={InformationDataSource}
        isMobile={this.state.isMobile}
      />,
      
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
