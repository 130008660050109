/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Banner from './Banner2';
import StoreLink from './StoreLink';
import Buffer from './Buffer';
import WukongPOS from './wukongPOS';
import WukongPOSmini from './wukongPOSmini';
import {
  Content10DataSource,
  Feature20DataSource,
  Content20DataSource,
  wukongPOSminiDataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class WuKongPOS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
   
    if (location.port) {
    
      this.setState({
        show: true,
      });
    }
  }

  render() {
    const children = [
     
      <Banner
        id="Banner"
        key="Banner"
      ></Banner>,
      <WukongPOS
        id="Feature2_0"
        key="Feature2_0"
        dataSource={Feature20DataSource}
        isMobile={this.state.isMobile}
      />,
      <Buffer
        id="Content2_0"
        key="Content2_0"
        dataSource={Content20DataSource}
        isMobile={this.state.isMobile}
      />,
      <StoreLink
        id="Content1_0"
        key="Content1_0"
        dataSource={Content10DataSource}
        isMobile={this.state.isMobile}
      />,
      <WukongPOSmini
        id="wukongPOSmini"
        key="wukongPOSmini"
        dataSource={wukongPOSminiDataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
