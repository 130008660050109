import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row,  } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';

class Content extends React.PureComponent {
  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const {
      wrapper,
      titleWrapper,
      page,
      OverPack: overPackData,
      childWrapper,
    } = dataSource;

    return (
      <div {...props} {...wrapper}>
        <div {...page}>
          <div {...titleWrapper}>
            {titleWrapper.children.map(getChildrenToRender)}
          </div>
          <OverPack {...overPackData}>
            <QueueAnim
              type="bottom"
              key="block"
              leaveReverse={false}
              component={Row}
              componentProps={childWrapper}
              duration='2000'
            >
              {childWrapper.children.map((block, i) => {
                const { children: item,  } = block;

                return (
                  <div key={i.toString()} >
                    <div className='boxItem'>
                      {/* {item.children.map(getChildrenToRender)} */}
                      <img src={item.children[0].children} alt="" />
                      <div className='boxItemContentBox'>
                        <p className='boxTitle'>{item.children[1].children}</p>
                        <p className='boxContent'>{item.children[2].children}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default Content;
