/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import RhtBanner from './Banner2';

import PromotionContent from './promotionContent';
import VariousModels from './variousModels';
import SmartModel from './smartModel';
import SalesModel from './salesModel';



import BusinessManagement from "./components/BusinessManagement"
import {
  Content10DataSource,
  Feature20DataSource,
  Feature220DataSource,
  Content20DataSource
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class RongHuiTong extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      this.setState({
        show: true,
      });
    }
  
  }

  render() {
    const children = [
      <RhtBanner
        id="RhtBanner"
        key="RhtBanner"
      />,
     
      this.state.isMobile?<h1 key="h1" style={{ textAlign: "center", marginBottom: "0px", marginTop: "41px", fontSize: 26,  }}>解决行业痛点</h1>:<h1 key="h1" style={{ textAlign: "center", marginBottom: "26px", marginTop: "130px", fontSize: 32,  }}></h1>,
      <BusinessManagement key='BusinessManagement' ></BusinessManagement>,
      <VariousModels
        id="Feature2_0"
        key="VariousModels"
        dataSource={Feature20DataSource}
        isMobile={this.state.isMobile}
      />,
      <PromotionContent
        id="Content1_0"
        key="PromotionContent"
        dataSource={Content10DataSource}
        isMobile={this.state.isMobile}
      />,
      <SalesModel
        id="Feature2_0"
        key="SalesModel"
        dataSource={Feature220DataSource}
        isMobile={this.state.isMobile}
      />,
      <SmartModel
        id="Content1_0"
        key="SmartModel"
        dataSource={Content20DataSource}
        isMobile={this.state.isMobile}
      />,

    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
