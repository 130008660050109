import React, { Component } from 'react';
import './index.less'
import rhtStpMap from '../../../../../assets/index'
import TweenOne from 'rc-tween-one';
class index extends Component {
    render() {
        return (
            <TweenOne
                animation={{
                    opacity: 0, type: 'from', duration: 2000, 
                    ease: 'easeOutQuad',
                    x: '-=30',
                }}

            >
                <div className='solve-problem'>
                    <div className='solve-problem-box'>
                        <ul className='solve-problem-box-list'>
                            {
                                rhtStpMap.map((item, index) => {
                                    return <li className='solve-problem-box-list-item' key={index}>
                                        <div>
                                            <img src={item.url} alt="" />
                                        </div>
                                        <span >{item.problem}</span>
                                    </li>
                                  

                                })
                            }
                        </ul>
                    </div>
                </div>
            </TweenOne>
        );
    }
}

index.propTypes = {

};

export default index;