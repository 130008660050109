import React, { Component } from 'react';
import {erpSolutionBanner} from '../../../assets'
class Banner2 extends Component {
    render() {
        return (
            <div>
                    <div>
                        <img style={{
                            width:'100%'
                        }} src={erpSolutionBanner.url} alt="" />
                    </div>
            </div>
        );
    }
}


export default Banner2;