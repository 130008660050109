export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: '产品名', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: '产品标语介绍',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: '产品的详细说明，如是什么东西之类的文字',
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#',
          className: 'banner5-button',
          type: 'primary',
          children: '开始使用',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
  },
};

export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper private-wrapper' },
  OverPack: { className: 'home-page private', playScale: 0.3 },
  imgWrapper: { className: 'private-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/private.png"),
  },
  textWrapper: { className: 'private-text', md: 14, xs: 24 },
  title: { className: 'private-title', children: '私有云架构优势兼备' },
  privateTitle1: {
    className: 'private-contentTitle',
    children:'技术架构稳定性高',
  },
  privatecontent1: {
    className: 'private-content',
    children:
      '具备高性能、高可靠度、长时间运行等特点，打通数据传输环节的任督二脉',
  },
  privateTitle2: {
    className: 'private-contentTitle',
    children:'服务器投入成本低',
  },
  privatecontent2: {
    className: 'private-content',
    children:
      '实现“数据库上云”，只需一台云服务器，即可保证所有业务正常流转运行',
  },
  privateTitle3: {
    className: 'private-contentTitle',
    children:'软件运行效率快',
  },
  privatecontent3: {
    className: 'private-content',
    children:
      '基于HTTP方式的三层架构，消除传统模式性能瓶颈，让软件即开即用',
  },
  privateTitle4: {
    className: 'private-contentTitle',
    children:'业务数据安全性强',
  },
  privatecontent4: {
    className: 'private-content',
    children:
      '数据仅用户独享，无私密性安全隐患，保障企业运营信息的独立和私有化',
  },
  privateTitle5: {
    className: 'private-contentTitle',
    children:'多商户管理灵活',
  },
  privatecontent5: {
    className: 'private-content',
    children:
      '一台云服务器同时承载多个商户，服务器托管便于集中式资源管理和服务运维',
  },
};

export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper oto-wrapper' },
  OverPack: { className: 'home-page oto', playScale: 0.3 },
  imgWrapper: { className: 'oto-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/OTO.png"),
  },
  textWrapper: { className: 'oto-text', md: 14, xs: 24 },
  title: { className: 'oto-title', children: '线上线下营销一体化' },
  otoTitle1: {
    className: 'oto-contentTitle',
    children:'微会员',
  },
  otocontent1: {
    className: 'oto-content',
    children:
      '微会员小程序，实现会员线上线下联动，让积分、充值、电子券数据共享同步',
  },
  otoTitle2: {
    className: 'oto-contentTitle',
    children:'微商城',
  },
  otocontent2: {
    className: 'oto-content',
    children:
      '到家商城提供丰富的营销组件，实现场景化、数字化、社交化、智能化的线上零售渠道',
  },
  otoTitle3: {
    className: 'oto-contentTitle',
    children:'外卖',
  },
  otocontent3: {
    className: 'oto-content',
    children:
      '接入京东到家、美团、饿了么平台的外卖订单，可与小程序商城聚合接单',
  },
  otoTitle4: {
    className: 'oto-contentTitle',
    children:'扫码购',
  },
  otocontent4: {
    className: 'oto-content',
    children:
      '顾客到店后通过小程序扫码自助购物，实现线上线下融和的消费场景',
  },
};


export const ManagementDataSource = {
  wrapper: { className: 'home-page-wrapper management-wrapper' },
  OverPack: { className: 'home-page management', playScale: 0.3 },
  imgWrapper: { className: 'management-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/Management.png"),
  },
  textWrapper: { className: 'management-text', md: 14, xs: 24 },
  title: { className: 'management-title', children: '辅助管理手段丰富' },
  managementTitle1: {
    className: 'management-contentTitle',
    children:'移动店务',
  },
  managementcontent1: {
    className: 'management-content',
    children:
      '店务小程序具备门店多项日常业务的操作能力，实现移动化作业，移动化运营',
  },
  managementTitle2: {
    className: 'management-contentTitle',
    children:'手机报表',
  },
  managementcontent2: {
    className: 'management-content',
    children:
      '移动式数据查询工具，了解门店业绩状况，掌握运营动态，辅助管理者决策',
  },
  managementTitle3: {
    className: 'management-contentTitle',
    children:'供应链',
  },
  managementcontent3: {
    className: 'management-content',
    children:
      '供应商辅助管理系统，提高运作效率，加快库存周转，提升商户与供应商的业务协同能力',
  },
  managementTitle4: {
    className: 'management-contentTitle',
    children:'钉钉',
  },
  managementcontent4: {
    className: 'management-content',
    children:
      '业务单据环节与钉钉互通，实现多级审批流程，随时随地办公，提高工作效率',
  },
  
};


export const CheckstandDataSource = {
  wrapper: { className: 'home-page-wrapper checkstand-wrapper' },
  OverPack: { className: 'home-page checkstand', playScale: 0.3 },
  imgWrapper: { className: 'checkstand-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/checkstand.png"),
  },
  textWrapper: { className: 'checkstand-text', md: 14, xs: 24 },
  title: { className: 'checkstand-title', children: '收银端触点多形态' },
  checkstandTitle1: {
    className: 'checkstand-contentTitle',
    children:'人工收银',
  },
  checkstandcontent1: {
    className: 'checkstand-content',
    children:
      '传统收银台模式，集中收银快捷流畅，支持Windows和Android系统',
  },
  checkstandTitle2: {
    className: 'checkstand-contentTitle',
    children:'自助收银',
  },
  checkstandcontent2: {
    className: 'checkstand-content',
    children:
      '自助POS收银，降低人力成本，减少排队等待，提升购物体验，改善运营效率',
  },
  checkstandTitle3: {
    className: 'checkstand-contentTitle',
    children:'移动收银',
  },
  checkstandcontent3: {
    className: 'checkstand-content',
    children:
      '适用于各种移动化收银场景，体现收银操作的及时性、便利性、快捷性',
  },
  
};
export const OperationDataSource = {
  wrapper: { className: 'home-page-wrapper operation-wrapper' },
  OverPack: { className: 'home-page operation', playScale: 0.3 },
  imgWrapper: { className: 'operation-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/operation.png"),
  },
  textWrapper: { className: 'operation-text', md: 14, xs: 24 },
  title: { className: 'operation-title', children: '业务管控全面高效' },
  operationTitle1: {
    className: 'operation-contentTitle',
    children:'业务管理精细',
  },
  operationcontent1: {
    className: 'operation-content',
    children:
      '从资料建档，到采购-销售-会员-库存-配送-结算各环节，业务覆盖度高，操作简单易用',
  },
  operationTitle2: {
    className: 'operation-contentTitle',
    children:'智能化程度高',
  },
  operationcontent2: {
    className: 'operation-content',
    children:
      '通过智能补货、智能配送、自动传秤、自动日结、自动备份等功能，智能完成日常业务操作',
  },
  operationTitle3: {
    className: 'operation-contentTitle',
    children:'促销形式多样',
  },
  operationcontent3: {
    className: 'operation-content',
    children:
      '支持特价、限量、批量、会员日、满减、满送、奇偶、阶梯、组合、商品包等多种促销形式',
  },
  operationTitle4: {
    className: 'operation-contentTitle',
    children:'外设兼容性好',
  },
  operationcontent4: {
    className: 'operation-content',
    children:
      '支持多品牌型号的收银机、电子秤、盘点机、打印机、读卡器等，常见外设即插即用',
  },
  operationTitle5: {
    className: 'operation-contentTitle',
    children:'数据转换灵活',
  },
  operationcontent5: {
    className: 'operation-content',
    children:
      '支持文本、excel、pdf多种格式数据导入导出，处理速度快，数据转换方便灵活',
  },
  operationTitle6: {
    className: 'operation-contentTitle',
    children:'报表实用性强',
  },
  operationcontent6: {
    className: 'operation-content',
    children:
      '报表体系健全，支持决策分析，看板内容丰富，图表呈现美观，可按需自定义报表',
  },
 
 
};

export const InformationDataSource = {
  wrapper: { className: 'home-page-wrapper information-wrapper' },
  OverPack: { className: 'home-page information', playScale: 0.3 },
  imgWrapper: { className: 'information-img', md: 10, xs: 24 },
  img: {
    children: require("../../../assets/information.png"),
  },
  textWrapper: { className: 'information-text', md: 14, xs: 24 },
  title: { className: 'information-title', children: '数据安全省心放心' },
  informationTitle1: {
    className: 'information-contentTitle',
    children:'本地备份',
  },
  informationcontent1: {
    className: 'information-content',
    children:
      '属于表级/行级备份，自动备份关键业务数据，服务器中毒后一键恢复数据，快速恢复营业',
  },

  informationTitle2: {
    className: 'information-contentTitle',
    children:'云备份',
  },
  informationcontent2: {
    className: 'information-content',
    children:
      '属于数据库级备份，定时备份完整数据库并存储到云空间，畅享云资源带来的福利',
  },

  informationTitle3: {
    className: 'information-contentTitle',
    children:'数据检测',
  },
  informationcontent3: {
    className: 'information-content',
    children:
      '提供数据检测功能，检测档案数据可能存在的风险和问题，可一键修复',
  },
  
};