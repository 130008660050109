/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';


import Banner from './banner2';
import PAS from './PAS';//产品和服务
import CustomerCase from './CustomerCase';
import Map from './Map';
import SolutionContent from './SolutionContent';

import {
  Content00DataSource,
  Content50DataSource,
  SolutionContentDataSource
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  
    if (location.port) {
    
      this.setState({
        show: true,
      });
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Banner key="Banner"></Banner>,
      <PAS
        id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}
      />,
      <SolutionContent key="SolutionContent" dataSource={SolutionContentDataSource}></SolutionContent>,
      <Map key="Map"></Map>,
      <CustomerCase
      id="Content5_0"
      key="Content5_0"
      dataSource={Content50DataSource}
      isMobile={this.state.isMobile}
    />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
