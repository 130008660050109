export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: '融汇通商业管理平台', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: '开启全渠道融合的新零售时代',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: '',
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#',
          className: 'banner5-button',
          type: 'primary',
          children: '',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children: require('../../../assets/rht.png'),
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: { className: 'feature6-title-text', children: '服务指标' },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '万' },
              toText: true,
              children: '116',
            },
            children: { className: 'feature6-text', children: '模型数据' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '亿' },
              toText: true,
              children: '1.17',
            },
            children: { className: 'feature6-text', children: '模型迭代数量' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '亿' },
              toText: true,
              children: '2.10',
            },
            children: { className: 'feature6-text', children: '训练样本数量' },
          },
        ],
      },
      {
        title: { className: 'feature6-title-text', children: '服务指标' },
        className: 'feature6-item',
        name: 'block1',
        children: [
          {
            md: 8,
            xs: 24,
            name: 'child0',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '万' },
              toText: true,
              children: '116',
            },
            children: { className: 'feature6-text', children: '模型数据' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child1',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '亿' },
              toText: true,
              children: '1.17',
            },
            children: { className: 'feature6-text', children: '模型迭代数量' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child2',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '亿' },
              toText: true,
              children: '2.10',
            },
            children: { className: 'feature6-text', children: '训练样本数量' },
          },
        ],
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper richmodescontent-wrapper' },
  OverPack: { className: 'home-page richmodescontent', playScale: 0.3 },
  imgWrapper: { className: 'richmodescontent-img', md: 10, xs: 24 },
  img: {
    // children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
    children: require("../../../assets/ability2.png"),
  },
  textWrapper: { className: 'richmodescontent-text', md: 14, xs: 24 },
  title: { className: 'richmodescontent-title', children: '丰富多样的促销模式' },
  tp: {
    className: 'richmodescontent-content-secondtitle',
    children: '传统促销',
  },//tp （传统促销）取英文开头第一个字母
  tpcontent: {
    className: 'richmodescontent-content',
    children:
      '降价、折扣、限时、限量、满折、赠送、搭赠，覆盖各消费层级消费场景。',
  },
  fp: {
    className: 'richmodescontent-content-secondtitle',
    children: '特色促销',
  },//tp （特色促销）取英文开头第一个字母
  fpcontent: {
    className: 'richmodescontent-content',
    children:
      '套餐、DIY组合等等，助力企业销售活力，推动销售规模不断升格。',
  },
};

export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper promotioncontent2-wrapper' },
  OverPack: { className: 'home-page promotioncontent2', playScale: 0.3 },
  imgWrapper: { className: 'promotioncontent2-img', md: 10, xs: 24 },
  img: {
    // children: 'https://zos.alipayobjects.com/rmsportal/tvQTfCupGUFKSfQ.png',
    children: require("../../../assets/ability1.png"),
  },
  textWrapper: { className: 'promotioncontent2-text', md: 14, xs: 24 },
  title: { className: 'promotioncontent2-title', children: '多业态支持' },

  wci: {
    className: 'promotioncontent2-content-secondtitle',
    children: '便利店、专卖店',
  },//称重收银一体化
  wcicontent: {
    className: 'promotioncontent2-content',
    children: '小店大连锁，总部统一管控、统一定价，提升品牌形象。',
  },
  pou: {
    className: 'promotioncontent2-content-secondtitle',
    children: '商超、百货、卖场',
  },//pou （使用场所）取英文开头第一个字母
  poucontent: {
    className: 'promotioncontent2-content',
    children: '精细的合同管理、多样的结算方式、精准的批次化库存管理，让企业成本清晰、运营流畅。',
  },
};



export const Feature220DataSource = {
  wrapper: { className: 'home-page-wrapper salesmodescontent2-wrapper' },
  OverPack: { className: 'home-page salesmodescontent2', playScale: 0.3 },
  imgWrapper: { className: 'salesmodescontent2-img', md: 10, xs: 24 },
  img: {
    // children: 'https://zos.alipayobjects.com/rmsportal/tvQTfCupGUFKSfQ.png',
    children: require("../../../assets/ability3.png"),
  },
  textWrapper: { className: 'salesmodescontent2-text', md: 14, xs: 24 },
  title: { className: 'salesmodescontent2-title', children: '销售统一' },

  msc: {
    className: 'salesmodescontent2-content-secondtitle',
    children: '主屏收银',
  },//msc （主屏收银）取英文开头第一个字母
  msccontent: {
    className: 'salesmodescontent2-content',
    children:'传统收银模式，成熟稳定。',
  },

  ssc: {
    className: 'salesmodescontent2-content-secondtitle',
    children: '双屏收银、自助收银',
  },//ssc （双屏收银、自助收银）取英文开头第一个字母
  ssccontent: {
    className: 'salesmodescontent2-content',
    children:'更新鲜的客户体验，释放企业放收银压力。',
  },


  
  sgha: {
    className: 'salesmodescontent2-content-secondtitle',
    children: '导购手持收银',
  },//sgha （导购手持收银）取英文开头第一个字母
  sghaccontent: {
    className: 'salesmodescontent2-content',
    children:'方便快捷，实现高端卖场一对一精准服务。',
  },
  distribution: {
    className: 'salesmodescontent2-content-secondtitle',
    children: '商城销售、外卖、到家',
  },//distribution （配送）
  distributioncontent: {
    className: 'salesmodescontent2-content',
    children:'不耽误每一单销售，线上下单、线下接单，完美融合。',
  },
};

export const Content20DataSource = {
  wrapper: { className: 'home-page-wrapper smartcontent1-wrapper' },
  OverPack: { className: 'home-page smartcontent1', playScale: 0.3 },
  imgWrapper: { className: 'smartcontent1-img', md: 10, xs: 24 },
  img: {
    // children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
    children: require("../../../assets/ability4.png"),
  },
  textWrapper: { className: 'smartcontent1-text', md: 14, xs: 24 },
  title: { className: 'smartcontent1-title', children: '智能化、移动化' },
  id: {
    className: 'smartcontent1-content-secondtitle',
    children: '智能要货智能配送',
  },//id （智能配送）取英文开头第一个字母
  idcontent: {
    className: 'smartcontent1-content',
    children:'解决要货难、配送难，提升商品周转率、消除积压库存，提升仓储运行效率。',
  },
  mobileManager: {
    className: 'smartcontent1-content-secondtitle',
    children: '门店业务移动化',
  },//id （门店业务移动化）取英文开头第一个字母
  mobileManagercontent: {
    className: 'smartcontent1-content',
    children:'实时高效处理门店业务数据，便捷验收、实时调价、快速审批。',
  },
};