import React, { Component } from 'react';
import { companyImg, partnerImgList, CertificatesBanner } from "../../assets"
import { enquireScreen } from 'enquire-js';
import './index.less'
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });

    }
    render() {
        const { isMobile } = this.state
        return (


            <div className='AboutUs'>
                <div className='AboutUsBox'>
                    <div className='AboutUsBoxTitle' >
                        <p className='gradient-text'>关于和正</p>
                        <p className='gradient-textBorderTop'>
                        </p>
                    </div>


                </div>
                <div className='companyIntroduction'>
                    <div className='companyIntroductionImgBox'>
                        <img src={companyImg.url} alt="" />
                    </div>
                </div>


                {isMobile ? <div className='partner'>
                    <div className='partnerBox'>
                        <div className='partnerBoxTitle'>
                            <p className='title'>战略合作伙伴</p>
                            <p className='BoderBottom'></p>
                        </div>

                        <div className='partnerList'>
                            <div className='theFristPartner'>
                                <div className='theFristPartnerBox'>
                                    <div className='theFristPartnerBoxContent'>
                                        <p>软通动力信息技术（集团）股份有限公司（以下简称“软通动力”）是中国领先的软件与信息技术服务商，致力于成为具有全球影响力的数字技术服务领导企业，企业数字化转型可信赖合作伙伴。2005年，公司成立于北京，坚持扎根中国，服务全球市场。目前，在全球43个城市设有100多个分支机构29个全球交付中心，员工70000余人。

                                        </p>
                                        <p>
                                            我们是软通动力的精英合作伙伴。
                                        </p>
                                    </div>
                                    <div className='theFristPartnerBoxImg'>
                                        <img src={partnerImgList[0].url} alt=""></img>
                                    </div>
                                </div>
                            </div>
                            <div className='theFristPartner'>
                                <div className='theFristPartnerBox'>

                                    <div className='theFristPartnerBoxContent'>
                                        <p>通联支付网络服务股份有限公司（简称通联支付）成立于2008年10月，是国内支付行业的先行者和开拓者，首批获得央行颁发的支付牌照，是行业领先的基于第三方支付的金融科技服务企业，总部位于上海。通联支付是中国支付清算协会副会长单位，是高新技术企业和上海市重点软件企业，并被授予“上海市著名商标”称号。
                                        </p>
                                        <p>
                                            我们的产品上架了通联支付应用平台。
                                        </p>
                                    </div>
                                    <div className='theFristPartnerBoxImg'>
                                        <img src={partnerImgList[1].url} alt=""></img>
                                    </div>
                                </div>
                            </div>
                            <div className='theFristPartner'>
                                <div className='theFristPartnerBox'>
                                    <div className='theFristPartnerBoxContent'>
                                        <p>华为创立于1987年，是全球领先的ICT（信息与通信）基础设施和智能终端提供商。目前华为约有19.7万员工，业务遍及170多个国家和地区，服务全球30多亿人口。
                                        </p>
                                        <p>
                                            我们的技术、性能、应用经过了华为实验室的层层测试成为了华为的高阶合作伙伴。
                                        </p>
                                    </div>
                                    <div className='theFristPartnerBoxImg'>
                                        <img src={partnerImgList[2].url} alt=""></img>
                                    </div>
                                </div>
                            </div>


                            <div className='Certificates'>
                                <div className='tCertificatesBox'>
                                    <img src={CertificatesBanner.url} alt="" srcset="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div> : <div className='partner'>
                    <div className='partnerBox'>
                        <div className='partnerBoxTitle'>
                            <p className='title'>战略合作伙伴</p>
                            <p className='BoderBottom'></p>
                        </div>

                        <div className='partnerList'>
                            <div className='theFristPartner'>
                                <div className='theFristPartnerBox'>
                                    <div className='theFristPartnerBoxContent'>
                                        <p>软通动力信息技术（集团）股份有限公司（以下简称“软通动力”）是中国领先的软件与信息技术服务商，致力于成为具有全球影响力的数字技术服务领导企业，企业数字化转型可信赖合作伙伴。2005年，公司成立于北京，坚持扎根中国，服务全球市场。目前，在全球43个城市设有100多个分支机构29个全球交付中心，员工70000余人。

                                        </p>
                                        <p>
                                            我们是软通动力的精英合作伙伴。
                                        </p>
                                    </div>
                                    <div className='theFristPartnerBoxImg'>
                                        <img src={partnerImgList[0].url} alt=""></img>
                                    </div>
                                </div>
                            </div>
                            <div className='theFristPartner'>
                                <div className='theFristPartnerBox'>
                                    <div className='theFristPartnerBoxImg'>
                                        <img src={partnerImgList[1].url} alt=""></img>
                                    </div>
                                    <div className='theFristPartnerBoxContent'>
                                        <p>通联支付网络服务股份有限公司（简称通联支付）成立于2008年10月，是国内支付行业的先行者和开拓者，首批获得央行颁发的支付牌照，是行业领先的基于第三方支付的金融科技服务企业，总部位于上海。通联支付是中国支付清算协会副会长单位，是高新技术企业和上海市重点软件企业，并被授予“上海市著名商标”称号。
                                        </p>
                                        <p>
                                            我们的产品上架了通联支付应用平台。
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className='theFristPartner'>
                                <div className='theFristPartnerBox'>
                                    <div className='theFristPartnerBoxContent'>
                                        <p>华为创立于1987年，是全球领先的ICT（信息与通信）基础设施和智能终端提供商。目前华为约有19.7万员工，业务遍及170多个国家和地区，服务全球30多亿人口。
                                        </p>
                                        <p>
                                            我们的技术、性能、应用经过了华为实验室的层层测试成为了华为的高阶合作伙伴。
                                        </p>
                                    </div>
                                    <div className='theFristPartnerBoxImg'>
                                        <img src={partnerImgList[2].url} alt=""></img>
                                    </div>
                                </div>
                            </div>


                            <div className='Certificates'>
                                <div className='tCertificatesBox'>
                                    <img src={CertificatesBanner.url} alt="" srcset="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                }



            </div>
        );
    }
}


export default AboutUs;