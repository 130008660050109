import React, { Component } from 'react';
import { SolutionImg } from '../../assets/index'
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import './less/SolutionContent.less'
class SolutionContent extends Component {
    render() {
        return (
            <OverPack
                {...this.props.dataSource}
            >
                <TweenOne
                    animation={{
                        opacity: 0,
                        type: 'from',
                        ease: 'easeOutQuad',
                        duration:2000,
                    }}
                >
                    <div className='solutionContent'>
                        <div className='solutionContentWapper'>
                            <div className='SolutionContentBox'>
                                <span key="1" className='SolutionContentBoxTitle'>全渠道全链路一体化解决方案</span>
                                <span key="2" className='SolutionContentBoxDescribe'>围绕场景提供工具，各业务紧密结合，全流程保障</span>
                                <img key="3" src={SolutionImg.url} alt="" />
                            </div>
                        </div>
                    </div>
                </TweenOne>
            </OverPack>
        );
    }
}

SolutionContent.propTypes = {

};

export default SolutionContent;