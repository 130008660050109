/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Banner from './Banner2';
import MemberPortrait from './MemberPortrait';
import Coupon from './Coupon';
import Integral from './Integral';
import MarketingSystem from './MarketingSystem ';
import ScrmImgList from './ScrmImgList';


import {
  MemberPortraitDataSource,
  CouponDataSource,
  IntegralDataSource,
  MarketingSystemDataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class RongHui extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    if (location.port) {
      this.setState({
        show: true,
      });
    }
  }

  render() {
    const children = [
      <Banner
        id="Banner"
        key="Banner"

      />,
      <ScrmImgList

      id="ScrmImgList"
      key="ScrmImgList"
      isMobile={this.state.isMobile}
      ></ScrmImgList>,
  

      <MemberPortrait
        id="MemberPortrait"
        key="MemberPortrait"
        dataSource={MemberPortraitDataSource}
        isMobile={this.state.isMobile}
      />,
      <Coupon
        id="CouponDataSource"
        key="CouponDataSource"
        dataSource={CouponDataSource}
        isMobile={this.state.isMobile}
      />,
      <Integral
        id="Integral"
        key="Integral"
        dataSource={IntegralDataSource}
        isMobile={this.state.isMobile}
      />,
      <MarketingSystem 
      id="MarketingSystem "
      key="MarketingSystem "
      dataSource={MarketingSystemDataSource}
      isMobile={this.state.isMobile}
    />,
   
      
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
