import React, { Component } from 'react';
import RongHui from "./RongHui"
import RongHuiTong from "./RongHuiTong"
import RongHuiYuan from "./RongHuiYuan"
import WuKongPOS from "./WuKongPOS"
class index extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
      
        this.SelectProduct()
    }




    SelectProduct = () => {
        if (window.location.href.includes('fusion')) {
            return <RongHui></RongHui>
        }
        if (window.location.href.includes('ronghuitong')) {
           
            return <RongHuiTong></RongHuiTong>
        }
        if (window.location.href.includes('wukongPOS')) {
            return <WuKongPOS></WuKongPOS>
        }
        if (window.location.href.includes('ronghuiyuan')) {
            return <RongHuiYuan></RongHuiYuan>
        }
    }
    render() {
        return (
            <div>
              { this.SelectProduct()}
            </div>
        );
    }
}

index.propTypes = {

};

export default index;