import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: require("../../assets/logo.png")
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '/',
          children: [{ children: '首页', name: 'text' ,}],
        },
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '/Products',
          children: [{ children: '产品', name: 'text' }],
        },
        subItem: [
          {
            name: '融汇',
            className: 'item-sub',
            href: '/products/fusion',
            children: {
              className: 'item-sub-item',
              children: [
                // {
                //   name: 'image0',
                //   className: 'item-image',
                //   children:
                //     'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                // },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '融汇商业管理平台',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '轻量型企业解决方案',
                },
              ],
            },
          },
          
          {
            name: '融汇通',
            className: 'item-sub',
            href: '/products/ronghuitong',
            children: {
              className: 'item-sub-item',
              children: [
                // {
                //   name: 'image0',
                //   className: 'item-image',
                //   children:
                //     'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                // },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '融汇通商业管理平台',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '中大型企业解决方案',
                },
              ],
            },
          },
          {
            name: '悟空POS',
            className: 'item-sub',
            href: '/products/wukongPOS',
            children: {
              className: 'item-sub-item',
              children: [
                // {
                //   name: 'image0',
                //   className: 'item-image',
                //   children:
                //     'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                // },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '客户多触点解决方案',
                },
                {
                  name: 'content',  
                  className: 'item-content',
                  children: '全渠道终端型触点一体化产品',
                },
              ],
            },
          },
          {
            name: '融汇员SCRM营销平台',
            className: 'item-sub',
            href: '/products/ronghuiyuan',
            children: {
              className: 'item-sub-item',
              children: [
                // {
                //   name: 'image0',
                //   className: 'item-image',
                //   children:
                //     'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                // },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '融汇员SCRM营销平台',
                },
                {
                  name: 'content',  
                  className: 'item-content',
                  children: '全渠道会员、营销、分析管理平台',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '',
          children: [{ children: '解决方案', name: 'text' }],
        },
        subItem: [
          // {
          //   name: 'sub0',
          //   className: 'item-sub',
          //   href: '/Solution/DetailePage1',
          //   children: {
          //     className: 'item-sub-item',
          //     children: [
          //       // {
          //       //   name: 'image0',
          //       //   className: 'item-image',
          //       //   children:
          //       //     'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
          //       // },
          //       {
          //         name: 'title',
          //         className: 'item-title',
          //         children: 'Ant Design',
          //       },
          //       {
          //         name: 'content',
          //         className: 'item-content',
          //         children: '企业级 UI 设计体系',
          //       },
          //     ],
          //   },
          // },
          
          {
            name: 'sub1',
            className: 'item-sub',
            href: '/Solution/business',
            children: {
              className: 'item-sub-item',
              children: [
                // {
                //   name: 'image0',
                //   className: 'item-image',
                //   children:
                //     'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
                // },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '智慧零售综合管理解决方案',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '开启全渠道融合的新零售时代',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: 'AboutUs',
          children: [{ children: '关于和正', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner1-title',
          children:
            '',
        },
        content: {
          className: 'banner1-content',
          children: '',
        },
        button: { className: 'banner1-button', children: '' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
          children:
            '',
        },
        content: {
          className: 'banner1-content',
          children: '',
        },
        button: { className: 'banner1-button', children: '' },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg2' },
        title: {
          className: 'banner1-title',
          children:
            '',
        },
        content: {
          className: 'banner1-content',
          children: '',
        },
        button: { className: 'banner1-button', children: '' },
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '', },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '产品与服务' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:require("../../assets/candy.png"),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '休闲食品',
            },
            { name: 'content', children: '适用于水果+零食门店，零食门店直营，加盟，等称重或标准品销售' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:require("../../assets/fish.png"),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '生鲜管理',
            },
            {
              name: 'content',
              children: '水果、蔬菜、肉食、熟食等适用于生鲜零售门店',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:require("../../assets/Shop.png"),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '专营专卖',
            },
            {
              name: 'content',
              children: '各类烟类、酒类、茶业、珠宝等,适用于专营专卖门店',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:require("../../assets/supermarket.png"),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '商超便利',
            },
            {
              name: 'content',
              children: '单体大超+批发、连锁标准超市、连锁便利店业态',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:require("../../assets/fresh.png"),
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '商业综合',
            },
            {
              name: 'content',
              children: '单体百货、百货+超市、购物中心管理',
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '客户案例', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: '',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 10,
    children: [
      {
        name: 'block1',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client1.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client2.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client3.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client4.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client5.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client6.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client7.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block8',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client8.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block9',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client9.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block10',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client10.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block11',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client11.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block12',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client12.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block13',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client13.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block14',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client14.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block15',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client15.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block16',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client16.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block17',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client17.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block18',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client18.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block19',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client19.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block20',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client20.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block21',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client21.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block22',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client22.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block23',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client23.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block24',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client24.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block25',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client25.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block26',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client26.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block27',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client27.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block28',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client28.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block29',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client29.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block30',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client30.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block31',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client31.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block32',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client32.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block33',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client33.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block34',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client34.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block35',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client35.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block36',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client36.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block37',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client37.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block38',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client38.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block39',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client39.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block40',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client40.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block41',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client41.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block42',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client42.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block43',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client43.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block44',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client44.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block45',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client45.png")
          },
          content: { children: '' },
        },
      },

      {
        name: 'block46',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client46.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block47',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client47.png")
          },
          content: { children: '' },
        },
      },
      {
        name: 'block48',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client48.png")
          },
          content: { children: '' },
        },
      },

      {
        name: 'block49',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client49.png")
          },
          content: { children: '' },
        },
      },


      {
        name: 'block50',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client50.png")
          },
          content: { children: '' },
        },
      },


      {
        name: 'block51',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client51.png")
          },
          content: { children: '' },
        },
      },


      {
        name: 'block52',
        className: 'block',
        md:3,
        xs: 21,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:require("../../assets/client52.png")
          },
          content: { children: '' },
        },
      },


    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '蚂蚁金融云提供专业的服务',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '基于阿里云强大的基础资源',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '企业资源管理' },
          content: {
            className: 'content3-content',
            children:
              '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云安全' },
          content: {
            className: 'content3-content',
            children:
              '按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云监控' },
          content: {
            className: 'content3-content',
            children:
              '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '移动' },
          content: {
            className: 'content3-content',
            children:
              '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '分布式中间件' },
          content: {
            className: 'content3-content',
            children:
              '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '大数据' },
          content: {
            className: 'content3-content',
            children:
              '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
          },
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:require("../../assets/FotterLogo.png")
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: '',
            },
          ],
        },
      },
      // {
      //   name: 'block1',
      //   xs: 24,
      //   md: 6,
      //   className: 'block',
      //   title: { children: '产品' },
      //   childWrapper: {
      //     children: [
      //       // { name: 'link0', href: '#', children: '产品更新记录' },
      //       { name: 'link1', href: '', children: '融汇商业管理平台' },
      //       { name: 'link2', href: '', children: '融汇通商业管理平台' },
      //       { name: 'link3', href: '', children: '和正销售终端管理软件' },
      //       { name: 'link4', href: '', children: '融汇员SCRM营销平台' },
      //     ],
      //   },
      // },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '联系我们' },
        childWrapper: {
          children: [
            { href: '', name: 'link0', children: '邮箱：Sunshine@qdhezheng.cn' },
            { href: '', name: 'link1', children: '微信：liuzhichen1208' },
            
          ],
        },
      },
      // {
      //   name: 'block3',
      //   xs: 24,
      //   md: 6,
      //   className: 'block',
      //   title: { children: '资源' },
      //   childWrapper: {
      //     children: [
      //       { href: '#', name: 'link0', children: 'Ant Design' },
      //       { href: '#', name: 'link1', children: 'Ant Motion' },
      //     ],
      //   },
      // },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <a style={{ color: '#999'}} href="http://beian.miit.gov.cn/">备案号:&nbsp;鲁ICP备&nbsp;2021021557号</a>
      </span>
    ),
  },
};


export const SolutionContentDataSource = {
  OverPack: { className: 'home-page richmodescontent', playScale: 0.1 },
  
  
};