
let rhtStpMap = [
    {
        url: require('./rht-stp1.png'),
        problem: '门店多且业态复杂'
    },
    {
        url: require('./rht-stp2.png'),
        problem: '促销营销手段单一'
    },
    {
        url: require('./rht-stp3.png'),
        problem: '线上线下无法融合'
    },
    {
        url: require('./rht-stp4.png'),
        problem: '实时库存无法实现'
    },
    {
        url: require('./rht-stp5.png'),
        problem: '生鲜/加工类管控难'
    },
    {
        url: require('./rht-stp6.png'),
        problem: '要货/配送数据不准确'
    },
]


export const SolutionImg = { url: require("./SolutionContent.png") }
export const QRCodeImg = { url: require("./QRCode.png") }
export const companyImg = { url: require("./company.png") }
export const goodProImg = { url: require("./goodPro.jpg") }

export const RhtBanner = { url: require("./rht.png") }

export const PosBanner = { url: require("./pos.png") }
export const scrmBanner = { url: require("./scrmBanner.png") }
export const RhBanner = { url: require("./rh.png") }
export const erpSolutionBanner = { url: require("./erpSolutionBanner.png") }
export const CertificatesBanner = { url: require("./zizhi.png") }

export const BannerImgList = [
    {
        url: require("../assets/Banner4.png")
    },
    {
        url: require("../assets/Banner5.png")
    },
    {
        url: require("../assets/Banner6.png")
    },
]
export const HZBusinessImgList = [
    {
        url: require("../assets/erpSolution.png")
    },
    {
        url: require("../assets/erpMainSolution1.png")
    },
    {
        url: require("../assets/erpMainSolution2.png")
    },
    {
        url: require("../assets/erpMainSolution3.png")
    },

]

export const ScrmImgList = [
    {
        url: require("../assets/h1.png"),
        name:'微信会员'
    },
    {
        url: require("../assets/h2.png"),
        name:'全渠道会员'
    },
    {
        url: require("../assets/h3.png"),
        name:'会员圈选'
    },
    {
        url: require("../assets/h4.png"),
        name:'会员标签'
    },
    {
        url: require("../assets/h5.png"),
        name:'积分管理'
    },
    {
        url: require("../assets/h6.png"),
        name:'储值卡券'
    },
    {
        url: require("../assets/h7.png"),
        name:'会员等级'
    },
    {
        url: require("../assets/h8.png"),
        name:'PLUS会员'
    },

]



export const partnerImgList = [
    {
        url: require("../assets/g2.png")
    },
    {
        url: require("../assets/g3.png")
    },
    {
        url: require("../assets/g4.png")
    },

]




export default rhtStpMap